<template>

<app-page>

	<app-page-head title="Requests" :count="list.length">

		<app-page-head-search v-model="searchText" :count="list.length" />
		
	</app-page-head>

	<app-page-content :is-empty="!list.length" empty-message="No requests found.">

		<com-item v-for="item in list" :key="item.id" :request="item" :highlight="searchText" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixList from '@/mixin/list'

export default {

	mixins: [mixList],

	components: {
		'com-item': () => import('./requests/Item')
	},

	data: function() {

		return {
			store: 'requests'
		}

	},

	methods: {

		search: function(value) {

			return this.$util.search(value.term, this.searchText) 

		}

	}

}

</script>

<style scoped>

</style>
